import { Box, Button, Center } from "@mantine/core";
import { forwardRef } from "react";
import { useStyles } from "../styles/PresentationBanner.styles";

export const PresentationBanner = forwardRef(function PresentationBanner({  onClickCTA, features }: any, ref: any) {

    const { classes, cx } = useStyles();

    /*

    <Title className={" animate__animated animate__slideInUp"}>Para locadores e locatários</Title>
            <Text color="dimmed">
                Build fully functional accessible web applications faster than ever – Mantine includes
                more than 120 customizable components and hooks to cover you in any situation
            </Text>*/

    //+ " animate__animated animate__fadeIn animate__delay-1s"        
    // Container className={classes.wrapper}
    return (
        <Box>
            <Center ref={ref}>
                <iframe className={classes.video} src="https://www.youtube.com/embed/rR7H3wi-iVg?controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Center>
            <Center>
                <Button className={classes.CTAButton} onClick={onClickCTA} variant="gradient" size="xl" radius="xl">
                    Veja as funcionalidades
                </Button>
            </Center>
        </Box>
    )

});