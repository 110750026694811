import {
  Container,
  Group,
  Header
} from '@mantine/core';
import logo from '../assets/images/logoipsum-297.svg';
import { useStyles } from '../styles/HeaderHome.styles';

interface HeaderSimpleProps {
  links: { link: string; label: string }[];
}

export function HeaderMegaMenu({ links }: HeaderSimpleProps) {

  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <a
      key={link?.label}
      href={link?.link}
      className={cx(classes?.link, /*{ [classes?.linkActive]: active === link?.link }*/)}
    >
      {link?.label}
    </a>
  ));

  return (
    <Header fixed height={60} zIndex={10}>
      <Container className={classes.header} size="lg">
        <img style={{ height: "30px" }} src={logo} />
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>
      </Container>
    </Header>
  );
  
}