import {
    Divider,
    List,
    LoadingOverlay,
    Modal,
    Text,
    ThemeIcon,
    rem
} from '@mantine/core';
import { useDisclosure, useScrollIntoView } from "@mantine/hooks";
import { IconCheck } from "@tabler/icons-react";
import { BannerFooter } from '../components/BannerFooter.component';
import { FaqWithImage } from "../components/FAQ.component";
import { FaqWithHeader } from "../components/FaqWithHeader.component";
import { FeatureComponent } from "../components/Feature.components";
import { FooterLinks } from "../components/Footer.component";
import { HeaderMegaMenu } from "../components/HeaderHome.component";
import { HeroContentLeft } from "../components/Hero.component";
import { PresentationBanner } from '../components/PresentationBanner.component';
import { useStyles } from '../styles/Hero.styles';
import { DividerWithContainerComponent } from '../components/DividerWithContainer.component';
import { useState } from 'react';
import { modals } from '@mantine/modals';
import { PreCadastroModal } from '../components/modals/PreCadastro.modal';

export function HomePage() {

    const { classes } = useStyles();
    const [loading, setLoading] = useState(true);

    const presentationCTA = useScrollIntoView<HTMLDivElement>({ offset: 60 });
    const featureCTA = useScrollIntoView<HTMLDivElement>({ offset: 60 });
    const faqCTA = useScrollIntoView<HTMLDivElement>({ offset: 60 });
    const modal = useDisclosure(false);

    const onFinishedLoad = () => {
        setLoading(false);
    }

    const openModal = () => modals.open({
        size: "75%",
        withCloseButton: false,
        onClose: () => { },
        children: (
            <>
                <PresentationBanner
                    onClickCTA={() => { featureCTA.scrollIntoView({ alignment: 'center' }); modals.closeAll(); }}
                    ref={presentationCTA.targetRef}
                />
            </>
        ),
    });

    return (
        <>
            <Modal opened={modal[0]} onClose={modal[1].close}>
                <PreCadastroModal onFinished={modal[1].close} />
            </Modal>
            <LoadingOverlay visible={loading} />
            <HeaderMegaMenu
                links={[
                    { label: "Cadastre-se", link: "https://portal.ez-health-project.com/precadastro/empresa" },
                    { label: "Login", link: "https://portal.ez-health-project.com" },
                ]}
            />
            <HeroContentLeft
                onFinishedLoad={onFinishedLoad}
                onClickCTA={() => openModal()}
            />
            <FaqWithHeader
                categories={[
                    { image: "assets/images/construcaocivil.jpg", label: "Construção Civil" },
                    { image: "assets/images/industrial.jpg", label: "Industrial" },
                    { image: "assets/images/agronegocio.jpg", label: "Agronegócio" },
                    { image: "assets/images/eventos.jpg", label: "Eventos" },
                    { image: "assets/images/logistica.jpeg", label: "Logística" },
                    { image: "assets/images/audiovisual.jpg", label: "Audiovisual" },
                    { image: "assets/images/telecomunicacoes.jpg", label: "Telecomunicações" },
                    { image: "assets/images/informatica.jpg", label: "Informática" }
                ]}
                onClickLocador={() => modal[1].open()}
                onClickLocatario={() => modal[1].open()}
                ref={faqCTA.targetRef}
            />
            <DividerWithContainerComponent />
            <FeatureComponent
                onClickCTA={() => faqCTA.scrollIntoView({ alignment: 'center' })}
                features={[
                    {
                        title: "Loja",
                        image: "https://placehold.co/600x400/EEE/31343C",
                        description:
                            <Text color="dimmed" mt="md">
                                Build fully functional accessible web applications faster than ever – Mantine includes
                                more than 120 customizable components and hooks to cover you in any situation
                            </Text>
                    },
                    {
                        title: "Pagamentos",
                        image: "https://placehold.co/600x400/EEE/31343C",
                        description:
                            <>
                                <Text color="dimmed" mt="md">
                                    Build fully functional accessible web applications faster than ever – Mantine includes
                                    more than 120 customizable components and hooks to cover you in any situation
                                </Text>
                                <List
                                    mt={30}
                                    spacing="sm"
                                    size="sm"
                                    icon={<ThemeIcon size={20} radius="xl">
                                        <IconCheck size={rem(12)} stroke={1.5} />
                                    </ThemeIcon>}
                                >
                                    <List.Item>
                                        Envie boletos
                                    </List.Item>
                                    <List.Item>
                                        Receba na sua conta pix
                                    </List.Item>
                                    <List.Item>
                                        <b>No annoying focus ring</b> – focus ring will appear only when user navigates with
                                        keyboard
                                    </List.Item>
                                </List>
                            </>
                    },
                    {
                        title: "Segurança",
                        image: "https://placehold.co/600x400/EEE/31343C",
                        description:
                            <Text color="dimmed" mt="md">
                                Build fully functional accessible web applications faster than ever – Mantine includes
                                more than 120 customizable components and hooks to cover you in any situation
                            </Text>,
                    }
                ]}
                ref={featureCTA.targetRef}
            />
            <DividerWithContainerComponent />

            <FaqWithImage
                questions={[
                    {
                        title: "Esse é um sistema de gestão dos ativos?",
                        description: "Não. Esta é uma plataforma de negociações, não substituindo os sistemas de gestão tradicional."
                    },
                    {
                        title: "Como é o processo de cadastro?",
                        description: "Após seu pré-cadastro, alguns documentos serão solicitados e entrevistas serão feitas, após o processo de análise retonaremos com o resultado da avaliação."
                    }
                ]}
            />
            <BannerFooter />
            <FooterLinks data={[
                {
                    links: [
                        {
                            label: "Foguete não tem ré",
                            link: "#"
                        },
                        {
                            label: "🚀🚀🚀",
                            link: "#"
                        }
                    ],
                    title: "Tchaullllll"
                }
            ]} />
        </>
    );
}