import React from 'react';
import ReactDOM from 'react-dom/client';
import { Notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import RouteListComponent from './routes';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider withNormalizeCSS withGlobalStyles>
    <Notifications />
    <ModalsProvider>
      <RouteListComponent />
    </ModalsProvider>
  </MantineProvider>
);

