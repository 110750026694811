import { Select, Input, TextInput, Checkbox, Group, Button, createStyles, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useId } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IMaskInput } from "react-imask";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },
    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },
}));


export const PreCadastroEmpresaForm = ({ onSubmit, reducer }: any) => {

    const id = useId();
    const { classes } = useStyles();

    const form = useForm({
        validateInputOnBlur: [
            "papel",
            "cnpj",
            "nome",
            "email",
            "telefone",
            "telefone",
            "lgpd",
        ],
        initialValues: {
            papel: "",
            cnpj: "",
            nome: "",
            email: "",
            telefone: "",
            lgpd: false,
        },
        validate: {
            papel: (value) => (value !== "" ? null : "Informe um objetivo"),
            cnpj: (value) => (value.length > 1 ? null : "Informe um CNPJ"),
            nome: (value) => (value.length > 1 ? null : "Informe um nome"),
            email: (value) => (value.length > 1 ? (/^\S+@\S+$/.test(value) ? null : 'Email inválido') : "Informe um email"),
            telefone: (value) => (value.length > 14 ? null : "Informe um telefone"),
            lgpd: (value) =>
                value ? null : "Você precisa concordar com os termos para finalizar",
        },
    });

    const onFormError = (event: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    const _onSubmit = (data: any) => {
        let cleanData = Object.assign({}, data);
        delete cleanData.lgpd;
        onSubmit(data);
    }

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <Select
                label="A minha empresa pretende..."
                placeholder="Escolha uma forma"
                data={[
                    {
                        value: "LOCADOR",
                        label: "Alugar meus equipamentos para outras empresas",
                    },
                    {
                        value: "LOCATARIO",
                        label: "Alugar equipamentos para minha empresa",
                    },
                    { value: "AMBOS", label: "Ambos" },
                ]}
                {...form.getInputProps("papel")}
            />
            <Input.Wrapper id={id} label="CNPJ da Empresa" required mt="md" error={form.errors?.cnpj}>
                <Input<any>
                    component={IMaskInput}
                    mask="00.000.000/0000-00"
                    id={id}
                    placeholder="00.000.000/0000-00"
                    {...form.getInputProps("cnpj")}
                />
            </Input.Wrapper>
            <TextInput
                mt="md"
                label="Nome para contato"
                placeholder="Paola Bracho"
                required
                {...form.getInputProps("nome")}
            />
            <TextInput
                mt="md"
                label="Email"
                placeholder="paola@familiabracho.com.mx"
                required
                {...form.getInputProps("email")}
            />
            <Input.Wrapper id={id} label="Telefone" required mt="md" error={form.errors?.telefone}>
                <Input<any>
                    component={IMaskInput}
                    mask="(00) 00000-0000"
                    id={id}
                    placeholder="(88) 98888-8888"
                    {...form.getInputProps("telefone")}
                />
            </Input.Wrapper>
            <Checkbox
                label="Concordo com os termos iniciais de gestão de dados e LGPD"
                mt="xl"
                size="xs"
                {...form.getInputProps("lgpd")}
            />
            <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                    fullWidth
                    type="submit"
                    disabled={!form.isValid()}
                    className={classes.control}
                    loading={reducer.state.status?.isSubmitting}
                >
                    Confirmar
                </Button>
            </Group>
        </form>
    );

}
