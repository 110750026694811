const identity = process.env.REACT_APP_IDENTITY_API_HOST;
const support = process.env.REACT_APP_SUPPORT_API_HOST;
const core = process.env.REACT_APP_CORE_API_HOST;
const extendedcore = process.env.REACT_APP_EXTENDED_CORE_API_HOST;
const functions = process.env.REACT_APP_FUNCTIONS_HOST;

export { identity, support, core, extendedcore, functions };

export class RemoteEndpoints {

  static consultaCNPJ: string = "https://publica.cnpj.ws/cnpj/";
  static consultaCEP: string = "https://viacep.com.br/ws/";

  static fileUpload: string = support + "/files/upload/single";
  static publicFileUpload: string = support + "/public/files/upload";
  static imageUpload: string = support + "/images/upload/single";

  static authenticateMFA: string = identity + "/oauth/token";
  static refreshToken: string = identity + "/oauth/token";

  static dadosBancarios: string = extendedcore + "/dados-bancarios";
  static dadoBancario: string = RemoteEndpoints.dadosBancarios + "/dados";
  static produtos: string = extendedcore + "/produtos";
  static marcas: string = extendedcore + "/marcas";
  static marca: string = RemoteEndpoints.marcas + "/marca";
  static classificacaoProdutos: string =
    extendedcore + "/classificacao-produto";

  static preCadastoEmpresas: string = extendedcore + "/pre-cadastros-empresas";
  static preCadastoEmpresa: string = RemoteEndpoints.preCadastoEmpresas + "/pre-cadastros";

  static preCadastoEmpresaPublicoBuscar = functions + "/pre-cadastro-empresa-buscar";
  static preCadastoEmpresaPublicoAtualizar = functions + "/pre-cadastro-empresa-atualizar";
  static preCadastoEmpresaPublicoUpload = functions + "/pre-cadastro-empresa-upload-documento";

  static stakeholdersPublicoBuscar: string = functions + "/pre-cadastro-stakeholder-buscar";
  static stakeholdersPublicoConfirmar: string = functions + "/pre-cadastro-stakeholder-confirmar";

  static stakeholders: string = core + "/stakeholders";
  static stakeholder: string = RemoteEndpoints.stakeholders + "/stakeholder";
  static empresas: string = core + "/empresas";
  static empresa: string = RemoteEndpoints.empresas + "/empresa";
  static pagamentos: string = core + "/pagamentos";
  static pagamento: string = RemoteEndpoints.pagamentos + "/pagamento";
  static prospeccoes: string = core + "/prospeccoes";
  static prospeccao: string = RemoteEndpoints.prospeccoes + "/prospeccao";
  static contratos: string = core + "/contratos";
  static contrato: string = RemoteEndpoints.contratos + "/contrato";
  static processos: string = core + "/processos";
  static processo: string = RemoteEndpoints.processos + "/processo";
  static solicitacoes: string = core + "/solicitacoes";
  static solicitacao: string = RemoteEndpoints.solicitacoes + "/solicitacao";
  static ativoitens: string = core + "/ativo-itens";
  static ativoitem: string = RemoteEndpoints.ativoitens + "/ativo-item";
  static ativos: string = core + "/ativos";
  static ativo: string = RemoteEndpoints.ativos + "/ativo";
  static buscarAtivos: string = RemoteEndpoints.ativos + "/search/all";
  static eventos: string = core + "/eventos";
  static eventosRecentes: string = RemoteEndpoints.eventos + "/recentes";
  static parametrizacaoEmpresa: string = core + "/parametrizacao-empresa";
  static lojas: string = core + "/lojas";
  static orcamentos: string = core + "/orcamentos";
  static orcamento: string = RemoteEndpoints.orcamentos + "/orcamento";
  static administradores: string = core + "/administrators";
  static administrador: any = this.administradores + "/administrator";

  static documentos: string = support + "/documents";
  static permissoes: string = support + "/permissions";
  static permissao: string = RemoteEndpoints.permissoes + "/permission";
  static dispositivos: string = support + "/authentication/details";
  static accounts: string = support + "/accounts";
  static account: string = this.accounts + "/account";
  static publicPasswordChangeRequest: string =
    support + "/public/account/change-password/request";
  static publicPasswordChangeConfirm: string =
    support + "/public/account/change-password/confirm";

}
