import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ResponseError, ResponseState } from "./models/ResponseState";
import { RequestConfig } from "./models/Model";

export class RemoteClient {
  public static async create(
    path: string,
    data: any,
    options?: RequestConfig,
  ): Promise<ResponseState> {
    try {
      const axiosConfig: AxiosRequestConfig = this.generateOptions(options);
      const response = await axios.post(path, data, axiosConfig);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e);
    }
  }

  public static async find(path: string, options?: RequestConfig) {
    try {
      const axiosConfig: AxiosRequestConfig = this.generateOptions(options);
      const response = await axios.get(path, axiosConfig);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e);
    }
  }

  public static async update(path: string, data: any, options?: RequestConfig) {
    try {
      const axiosConfig: AxiosRequestConfig = this.generateOptions(options);
      const response = await axios.put(path, data, axiosConfig);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e);
    }
  }

  public static async partialUpdate(
    path: string,
    data: any,
    options?: RequestConfig,
  ) {
    try {
      const axiosConfig: AxiosRequestConfig = this.generateOptions(options);
      const response = await axios.patch(path, data, axiosConfig);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e);
    }
  }

  public static async delete(path: string, options?: RequestConfig) {
    try {
      const axiosConfig: AxiosRequestConfig = this.generateOptions(options);
      const response = await axios.delete(path, axiosConfig);
      return this.handleResponse(response);
    } catch (e) {
      return this.handleError(e);
    }
  }

  public static handleResponse(response: AxiosResponse<any>): ResponseState {
    const returnResponse = new ResponseState();
    returnResponse.code = response.status;

    if (
      response.status === 200 ||
      response.status === 201 ||
      response.status === 102
    ) {
      returnResponse.data = response.data || response.status;
      return returnResponse;
    } else if (response.status === 204) {
      returnResponse.data = "OK";
      return returnResponse;
    } else if ([500, 400, 401, 404].includes(response.status)) {
      //returnResponse.error = response.data || response.status || response..message;
      return returnResponse;
    } else {
      return new ResponseState();
    }
  }

  private static processException(e: any) {
    if (e.code === "ERR_NETWORK") {
      return "Não foi possível acessar o serviço no momento.";
    } else if (e.code === "ERR_BAD_REQUEST") {
      return "Houve um problema para acessar o serviço.";
    } else {
      console.error(e);
      return e.message;
    }
  }

  private static handleError(e: any) {
    const response = new ResponseState();
    response.error = new ResponseError();
    response.error.status = e.response?.data?.status;
    response.error.message =
      e.response?.data?.message || RemoteClient.processException(e);
    response.status = e.response?.data?.status;
    return response;
  }

  private static generateOptions(config?: RequestConfig) {
    return {
      timeout: config?.timeout || 40000,
      headers: {
        ...config?.headers,
      },
    };
  }
}
