import { Container, Box, Center, Title, Button, Stack } from "@mantine/core"
import { useStyles } from "../styles/BannerFooter.styles";

export const BannerFooter = () => {

    const { classes } = useStyles();

    return (
        <Container className={classes.wrapper} size="lg">
            <Box className={classes.header}>
                <Stack>
                    <Title className={classes.title + " animate__animated animate__slideInUp"}>
                        Comece agora, com o seu pré-cadastro
                    </Title>
                    <Button 
                        component="a" 
                        href="https://portal.ez-health-project.com/precadastro/empresa" 
                        variant="light" 
                        size="xl" 
                        radius="xl" 
                        className={classes.control + " animate__animated animate__fadeIn"}
                    >
                        Iniciar
                    </Button>
                </Stack>
            </Box>
        </Container>
    )

}