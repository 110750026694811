import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  
  wrapper: {
    height: '100vh', 
    overflowY: 'hidden',
    paddingTop: theme.spacing.xl,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    [theme.fn.smallerThan('sm')]: {
      paddingTop: theme.spacing.md,
      paddingBottom: `calc(${theme.spacing.md} * 2)`,
    },
  },

  video: {
    border: 'none',
    width: '100%',
    height: '60vh', 
    [theme.fn.smallerThan('sm')]: {
      height: '40vh',
    },
  },

  CTAButton:{
    marginTop: '2%'
  }

}));