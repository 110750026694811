import { Box, Card, Container } from "@mantine/core"
import { PreCadastroModal } from "../../components/modals/PreCadastro.modal"

export const IniciarPreCadastroEmpresaPage = () => {

    return (
        <>
            <>
                <Container>
                    <Card p="xl" mt={50} shadow="xl" mx={200}>
                        <PreCadastroModal onFinished={() => console.log()} />
                    </Card>
                </Container>
            </>
        </>
    )

}
