import {
  Button,
  Center,
  Container,
  Group,
  Overlay,
  SimpleGrid,
  Text,
  Title,
  UnstyledButton
} from '@mantine/core';
import { forwardRef } from 'react';
import { useStyles } from '../styles/FaqWithHeader.styles';


interface FaqWithHeaderProps {
  // Your other props here
  categories: any;
  onClickLocador: () => void; 
  onClickLocatario: () => void; 
}

export const FaqWithHeader = forwardRef(function FaqWithHeader(props: FaqWithHeaderProps, ref: any) {

  const { classes } = useStyles();

  const items = props.categories.map((category: any) => (
    <UnstyledButton
      style={{ backgroundImage: `url(${category.image})` }}
      className={classes.categoryCard}
      key={category.label}
    >
      <Overlay color="#000" opacity={0.6} zIndex={1} />
      <Text size="xl" align="center" weight={700} className={classes.categoryLabel}>
        {category.label}
      </Text>
    </UnstyledButton>
  ));

  return (
    <Container className={classes.wrapper} ref={ref}>
      <Title className={classes.title + " animate__animated animate__slideInUp"}>Para todas as áreas</Title>
      <Container size={560} p={0} mb="xl">
        <Text size="sm" className={classes.description + " animate__animated animate__fadeIn "}>
          Alugue máquinas, ferramentas e veiculos para todos ....
        </Text>
      </Container>
      <SimpleGrid ref={ref} cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
        {items}
      </SimpleGrid>
      <Center>
        <Group>
          <Button onClick={props.onClickLocador} variant="gradient" size="xl" radius="xl" mr="md" className={classes.control + " animate__animated animate__fadeIn animate__delay-1s"}>
            Quero ser locador
          </Button>
          <Button onClick={props.onClickLocatario} variant="gradient" size="xl" radius="xl" className={classes.control + " animate__animated animate__fadeIn animate__delay-1s"}>
            Quero ser locatário
          </Button>
        </Group>
      </Center>
    </Container>
  );

});