import { useReducer } from "react";
import { State } from "./Model";

export const ActionTypes = {
  error: "ERROR",
  fetching: "FETCHING",
  deleting: "DELETING",
  initiating: "INITIATING",
  submitting: "SUBMITTING",
};

export const initialState: State<any> = {
  status: {
    isFetching: false,
    isSubmitting: false,
    isUpdating: false,
    isDeleting: false,
    isDeleted: false,
    isSubmitted: false,
    isUpdated: false,
    isInitiating: false,
    isSuccessful: false,
    isComplete: false,
    isFailed: false,
  },
  data: {
    object: null,
    page: null,
    error: null,
    id: null,
  },
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ActionTypes.error:
      return {
        ...state,
        status: { isFailed: true },
        data: { ...state.data, error: action.payload },
      };
    case ActionTypes.fetching:
      return {
        ...state,
        status: { isFetching: true },
      };
    case ActionTypes.deleting:
      return {
        ...state,
        status: { isDeleting: true },
        data: { ...state.data, id: action.payload.id },
      };
    case "SUBMITTING":
      return {
        ...state,
        status: { isSubmitting: true },
      };
    case "UPDATING":
      return {
        ...state,
        status: { isUpdating: true },
      };
    case "SUCCESS_PAGE":
      return {
        ...state,
        status: { isSuccessful: true },
        data: { object: null, page: action.payload },
      };
    case "SUCCESS_SINGLE":
      return {
        ...state,
        status: { isSuccessful: true },
        data: { object: action.payload, page: state.data?.page },
      };
    case "SUBMIT_SUCCESS":
      if (state?.data?.page) {
        if (state?.data?.page?.content) {
          state.data.page.content.push(action.payload);
        } else {
          state.data.page.content = [action.payload];
        }
      }
      return {
        ...state,
        status: { isSuccessful: true, isSubmitted: true, isSubmitting: false },
        data: { object: action.payload, page: state.data.page },
      };
    case "UPDATE_SUCCESS": {
      const resultData = state?.data?.page?.content?.filter(
        (data: any) => data.id !== action.payload.id,
      );
      resultData?.push(action.payload);

      if (state.data.page) {
        state.data.page.content = resultData;
      }

      return {
        ...state,
        status: { isSuccessful: true, isUpdated: true, isUpdating: false },
        data: { object: action.payload, page: state?.data?.page },
      };
    }
    case "DELETE_SUCCESS": {
      const resultData = state?.data?.page?.content?.filter(
        (data: any) => data.id !== action.payload.id,
      );

      if (state.data.page) {
        state.data.page.content = resultData;
      }

      return {
        ...state,
        status: { isSuccessful: true, isDeleted: true, isDeleting: false },
        data: { object: action.payload, page: state.data.page },
      };
    }
    case ActionTypes.initiating: {
      return {
        ...state,
        status: { isInitiating: true },
      };
    }
    default:
      return state;
  }
};
