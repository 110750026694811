import { Button, Container, Overlay, Text, Title } from '@mantine/core';
import 'animate.css';
import { useStyles } from '../styles/Hero.styles';

export function HeroContentLeft({ onClickCTA, onFinishedLoad }: any) {

  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <img src="assets/images/hero.jpg" style={{ width: 0, height: 0 }} onLoad={onFinishedLoad} />
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container} size="lg">
        <Title className={classes.title + " animate__animated animate__slideInUp"}>A plataforma definitiva para locação de ativos</Title>
        <Text className={classes.description + " animate__animated animate__slideInUp"} size="xl" mt="xl">
          Encontre, negocie e alugue máquinas, ferramentas, veículos, itens de tecnologia de diversas locadoras em um lugar e em poucos cliques.
          Ou, negocie seus ativos com facilidade em seu processo de locação com novos clientes.
        </Text>

        <Button onClick={onClickCTA} variant="gradient" size="xl" radius="xl" className={classes.control + " animate__animated animate__fadeIn animate__delay-1s"}>
          Saiba mais
        </Button>
      </Container>
    </div>
  );
  
}