import {
  Box,
  Button,
  Center,
  Container,
  Group,
  Image,
  Title
} from '@mantine/core';
import { forwardRef, useState } from 'react';
import { useStyles } from '../styles/Features.styles';


export const FeatureComponent = forwardRef(function FeatureComponent({ onClickCTA, features }: any, ref: any) {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper}>
      <Box mt="xl">
        <Title className={classes.title}>
          Para todos os processos. Tudo para facilitar suas locações.
        </Title>
        <Center>
          <Group mt="xl">
            {
              features.map((item: any, index: number) =>
                <Button onClick={() => setSelectedIndex(index)} variant={index === selectedIndex ? "gradient" : "outline"} color="gray" radius="xl" size={index === selectedIndex ? "lg" : "md"} compact>
                  {item.title}
                </Button>
              )
            }
          </Group>
        </Center>
        <div></div>
        <div className={classes.inner}>
          <Image src={features[selectedIndex].image} className={classes.image} m="xl" />
          <div ref={ref} className={classes.content}>
            {features[selectedIndex].description}
          </div>
        </div>
      </Box>
      <Center>
        <Button onClick={onClickCTA} variant="gradient" size="xl" radius="xl" className={" animate__animated animate__fadeIn animate__delay-1s"}>
          Veja as funcionalidades
        </Button>
      </Center>
    </Container>
  );


});