import { BlockBlobClient } from "@azure/storage-blob";

export class BlobStorageService {

    public static async uploadFiles(files: File[], sas: string): Promise<any> {

        const blobServiceClient = new BlockBlobClient(sas);
        const containerClient = blobServiceClient.getBlockBlobClient();

        const promises = [];
        for (const file of files) {
            promises.push(containerClient.uploadData(file, { blobHTTPHeaders: { blobContentType: file.type } }));
        }

        return Promise.all(promises);
    }

}