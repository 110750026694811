export class ResponseError {
  status?: number;
  message?: string;
}

export enum ResponseStatus {
  COMPLETED,
  SUCCESS,
  FAILED,
}

export class ResponseState {
  code?: number;
  status?: ResponseStatus;
  data?: any;
  error?: ResponseError;
}
