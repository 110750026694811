import { Accordion, Box, Col, Container, Grid, Group, Image, Title } from '@mantine/core';
import faq from '../assets/vectors/faq.svg';
import { useStyles } from '../styles/FAQ.styles';

export function FaqWithImage({ questions }: any) {

  const { classes } = useStyles();

  return (
    <Container className={classes.wrapper}>
      <Group noWrap>
        <Box>
          <Image src={faq} alt="Perguntas frequentes" />
        </Box>
        <Box>
          <Title order={2} ta="left" className={classes.title}>
            Perguntas frequentes
          </Title>
          <Accordion chevronPosition="right" defaultValue="0" variant="separated">
            {
              questions?.map((item: any, index: number) =>
                <Accordion.Item className={classes.item} value={`${index}`} key={index}>
                  <Accordion.Control>{item.title}</Accordion.Control>
                  <Accordion.Panel>{item.description}</Accordion.Panel>
                </Accordion.Item>
              )
            }
          </Accordion>
        </Box>
      </Group>
    </Container>
  );

}