import { Button, Group, TextInput, createStyles, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useId } from "react";

const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(26),
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
      [theme.fn.smallerThan("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    control: {
      [theme.fn.smallerThan("xs")]: {
        width: "100%",
        textAlign: "center",
      },
    },
  }));

export const PreCadastroInformacoesForm = ({ reducer, onSubmit }: any) => {

    const id = useId();
    const id2 = useId();
    const { classes } = useStyles();
    const { status, data } = reducer.state;

    const form = useForm({
        validateInputOnBlur: [
            "inscricaoEstadualMunicipal",
        ],
        initialValues: {
            inscricaoEstadualMunicipal: "",
        },
        validate: {
            inscricaoEstadualMunicipal: (value) =>
                value !== "" ? null : "Informe uma inscriçaõ municipal/estadual"
        },
    });

    const _onSubmit = (submitData: any) => {
        onSubmit(submitData);
    }

    const onFormError = (event: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <TextInput
                label="Inscrição Municipal/Estadual"
                placeholder="Insira a inscrição"
                required
                {...form.getInputProps("inscricaoEstadualMunicipal")}
            />
            <TextInput
                mt="md"
                label="Site"
                description="Opcional"
                placeholder="Insira o site da empresa"
                {...form.getInputProps("site")}
            />
            <TextInput
                mt="md"
                label="Linkedin"
                description="Opcional"
                placeholder="Insira o linkedin da empresa"
                {...form.getInputProps("linkedin")}
            />
              <TextInput
                mt="md"
                label="Instagram"
                description="Opcional"
                placeholder="Insira o instagram da empresa"
                {...form.getInputProps("instagram")}
            />
            <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                    fullWidth
                    type="submit"
                    disabled={!form.isValid()}
                    className={classes.control}
                    loading={reducer.state.status?.isSubmitting}
                >
                    Confirmar
                </Button>
            </Group>
        </form>
    )

}