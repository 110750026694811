import { Button, Group, Input, TextInput, createStyles, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useId } from "react";
import { IMaskInput } from "react-imask";

const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(26),
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
      [theme.fn.smallerThan("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    control: {
      [theme.fn.smallerThan("xs")]: {
        width: "100%",
        textAlign: "center",
      },
    },
  }));

export const PreCadastroReferenciaComercialForm = ({ reducer, onSubmit }: any) => {

    const id = useId();
    const id2 = useId();
    const { classes } = useStyles();
    const { status, data } = reducer.state;

    const form = useForm({
        validateInputOnBlur: [
            "empresaReferencia1",
            "empresaReferencia2",
            "telefoneReferencia1",
            "telefoneReferencia2",
        ],
        initialValues: {
            empresaReferencia1: "",
            telefoneReferencia1: "",
            empresaReferencia2: "",
            telefoneReferencia2: "",
        },
        validate: {
            empresaReferencia1: (value) =>
                value !== "" ? null : "Informe uma empresa de referência comercial",
            telefoneReferencia1: (value) =>
                value !== "" ? null : "Informe o telefone da empresa",
            empresaReferencia2: (value) =>
                value.length > 1 ? null : "Informe uma empresa de referência comercial",
            telefoneReferencia2: (value) =>
                value !== "" ? null : "Informe o telefone da empresa",
        },
    });

    const _onSubmit = (submitData: any) => {
        if (!data?.object?.cartaoCNPJ) {
            notifications.show({
                message: "É necessário enviar o cartão CPNJ",
                color: "orange",
            });
            return;
        } else if (!data?.object?.balancoFinanceiro) {
            notifications.show({
                message: "É necessário enviar o balanço financeiro",
                color: "orange",
            });
            return;
        } else if (!data?.object?.comprovantePagamento) {
            notifications.show({
                message: "É necessário enviar um comprovante de pagamento recente",
                color: "orange",
            });
            return;
        }
        onSubmit(submitData);
    }

    const onFormError = (event: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <TextInput
                mt="md"
                label="Empresa Referência 1"
                placeholder="Insira o nome da empresa"
                required
                {...form.getInputProps("empresaReferencia1")}
            />
            <Input.Wrapper id={id} label="Telefone Referencia 1" mt="md">
                <Input<any>
                    component={IMaskInput}
                    mask="(00) 00000-0000"
                    id={id}
                    required
                    placeholder="(88) 98888-8888"
                    {...form.getInputProps("telefoneReferencia1")}
                />
            </Input.Wrapper>
            <TextInput
                mt="md"
                label="Empresa Referência 2"
                placeholder="Insira o nome da empresa"
                required
                {...form.getInputProps("empresaReferencia2")}
            />
            <Input.Wrapper id={id} label="Telefone Referencia 2" mt="md">
                <Input<any>
                    component={IMaskInput}
                    mask="(00) 00000-0000"
                    id={id2}
                    required
                    placeholder="(88) 98888-8888"
                    {...form.getInputProps("telefoneReferencia2")}
                />
            </Input.Wrapper>
            <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                    fullWidth
                    type="submit"
                    disabled={!form.isValid()}
                    className={classes.control}
                    loading={reducer.state.status?.isSubmitting}
                >
                    Confirmar
                </Button>
            </Group>
        </form>
    )

}