import { RemoteClient } from "..";
import { RemoteOperationParameters } from "./Model";


export function useRemoteOperation({ reducer }: any) {
  const remove = async (parameters: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams(requestParameters).toString();
    const queryParams = urlSearchParams ? `/?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters?.headers };
    const id = parameters?.id;

    reducer.dispatch({ type: "DELETING", payload: { id: id } });
    const result = await RemoteClient.delete(endpoint, await headers);
    if (result.data) {
      reducer.dispatch({ type: "DELETE_SUCCESS", payload: { id: id } });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  const fetchPage = async (parameters?: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams({
      page: requestParameters?.page?.page || 0,
      size: requestParameters?.page?.size || 10,
      ...requestParameters,
    }).toString();

    const queryParams = urlSearchParams ? `?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters?.headers };

    reducer.dispatch({ type: "FETCHING", payload: null });
    const result = await RemoteClient.find(endpoint, await headers);
    if (result.data) {
      reducer.dispatch({ type: "SUCCESS_PAGE", payload: result.data });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  const fetchSingle = async (parameters?: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams(requestParameters).toString();
    const queryParams = urlSearchParams ? `/?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters?.headers };

    reducer.dispatch({ type: "FETCHING", payload: null });
    const result = await RemoteClient.find(endpoint, await headers);
    if (result.data) {
      reducer.dispatch({ type: "SUCCESS_SINGLE", payload: result.data });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  const create = async (parameters: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams(requestParameters).toString();
    const queryParams = urlSearchParams ? `/?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters?.headers };
    const data = parameters.data;

    reducer.dispatch({ type: "SUBMITTING", payload: null });
    const result = await RemoteClient.create(endpoint, data, await headers);
    if (result.data) {
      reducer.dispatch({ type: "SUBMIT_SUCCESS", payload: result.data });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  const update = async (parameters: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams(requestParameters).toString();
    const queryParams = urlSearchParams ? `/?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters.headers };
    const data = parameters.data;

    reducer.dispatch({ type: "UPDATING", payload: null });
    const result = await RemoteClient.update(endpoint, data, await headers);
    if (result.data) {
      reducer.dispatch({ type: "UPDATE_SUCCESS", payload: result.data });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  const patch = async (parameters: RemoteOperationParameters) => {
    const requestParameters = parameters?.requestParameters;
    const urlSearchParams = new URLSearchParams(requestParameters).toString();
    const queryParams = urlSearchParams ? `/?${urlSearchParams}` : "";
    const endpoint = parameters?.endpoint + queryParams;
    const headers = { ...parameters.headers };
    const data = parameters.data;

    reducer.dispatch({ type: "UPDATING", payload: null });
    const result = await RemoteClient.partialUpdate(
      endpoint,
      data,
      await headers,
    );
    if (result.data) {
      reducer.dispatch({ type: "UPDATE_SUCCESS", payload: result.data });
    } else if (result.error) {
      reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  return {
    create: create,
    fetchPage: fetchPage,
    fetchSingle: fetchSingle,
    patch: patch,
    update: update,
    remove: remove,
  };
}
