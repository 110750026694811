import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  
  wrapper: {
    height: '100vh', //rem(900),
    paddingTop: theme.spacing.xl,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: `calc(${theme.spacing.xl} * 1)`,
    paddingBottom: `calc(${theme.spacing.xl} * 3)`,
  },

  content: {
    maxWidth: rem(480),
    //marginRight: `calc(${theme.spacing.xl} * 4)`,
    //marginLeft: `calc(${theme.spacing.xl} * 4)`,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
      marginLeft: 0,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(44),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

}));