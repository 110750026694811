import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    wrapper: {
      height: '100vh', 
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      marginBottom: theme.spacing.md,
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(28),
        textAlign: 'left',
      },
    },
  
    description: {
      textAlign: 'center',
  
      [theme.fn.smallerThan('sm')]: {
        textAlign: 'left',
      },
    },
  
    categoryCard: {
      height: rem(160),
      position: 'relative',
      backgroundSize: '100%',
      backgroundPosition: 'center',
      color: theme.white,
      borderRadius: theme.radius.lg,
      padding: theme.spacing.xl,
      overflow: 'hidden',
      transition: 'background-size 300ms ease',
  
      '&:hover': {
        backgroundSize: '105%',
      },
    },
  
    control: {
      marginTop: `calc(${theme.spacing.xl} * 1.5)`,
  
      [theme.fn.smallerThan('sm')]: {
        width: '100%',
      },
    },
  
    categoryLabel: {
      color: theme.white,
      zIndex: 2,
      position: 'relative',
    },
  
  }));