import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(26),
      fontWeight: 900,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
      [theme.fn.smallerThan("xs")]: {
        flexDirection: "column-reverse",
      },
    },
    control: {
      [theme.fn.smallerThan("xs")]: {
        width: "100%",
        textAlign: "center",
      },
    },
  
    video: {
      border: "none",
      width: "100%",
      height: "60vh", //rem(900),
    },
  }));
  