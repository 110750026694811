import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Group,
  Image,
  LoadingOverlay,
  Paper,
  PasswordInput,
  PinInput,
  Stepper,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useId } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconCircleCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./FinalizacaoCadastroStakeholder.styles";
import { useRemoteOperation } from "../../hooks";
import { useRemoteDataReducer } from "../../hooks/remote/useCRUDHooks";
import { RemoteEndpoints } from "../../constants/RemoteEndpoints.const";
import { Hidden } from "../../shared/providers/infrastructure/Hidden";


export const FinalizacaoCadastroStakeholder = () => {

  const location = useLocation();
  const { classes } = useStyles();
  const reducer = useRemoteDataReducer();
  const reducerPreConsulta = useRemoteDataReducer();
  const key = new URLSearchParams(useLocation().search).get("key");
  const navigate = useNavigate();
  const { create } = useRemoteOperation({ reducer: reducer });
  const { fetchSingle } = useRemoteOperation({ reducer: reducerPreConsulta });
  const { data, status } = reducer.state;
  const [active, setActive] = useState(
    reducerPreConsulta.state.data?.object?.status === "CADASTRADO" ? 2 : 0,
  );

  useEffect(() => {
    if (key === null) {
      navigate("/404");
    } else {
      findItem(key);
    }
  }, [key]);

  useEffect(() => {
    if (reducer.state.status?.isSubmitted) {
      setActive(1);
    }
    if (
      reducerPreConsulta.state.status?.isSuccessful &&
      reducerPreConsulta.state.data?.object?.status === "CADASTRADO"
    ) {
      setActive(2);
    }
    if (reducerPreConsulta.state.status?.isFailed) {
      openErrorModal();
    }
    if (reducer.state.status?.isFailed) {
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    }
  }, [reducer.state.status, reducerPreConsulta.state.status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
    findEmpresa(key);
  }, [location]);

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));

  const findEmpresa = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.stakeholdersPublicoBuscar}/${key}`,
      properties: { reducer: reducerPreConsulta },
    });

  const form = useForm({
    validateInputOnBlur: ["password", "password_confirm", "lgpd"],
    initialValues: {
      password: "",
      password_confirm: "",
      nome: "",
      email: "",
      telefone: "",
      lgpd: false,
    },
    validate: {
      password: (value) => (value !== "" ? null : "Informe uma senha"),
      password_confirm: (value) =>
        value.length > 1 ? null : "Confirme sua senha",
      lgpd: (value) =>
        value ? null : "Você precisa concordar com os termos para finalizar",
    },
  });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.stakeholdersPublicoBuscar}/${key}`,
      properties: { reducer: reducer },
    });

  const openErrorModal = () => modals.openConfirmModal({
    title: 'Please confirm your action',
    centered: true,
    children: (
      <Text size="sm">
        This action is so important that you are required to confirm it with a modal. Please click
        one of these buttons to proceed.
      </Text>
    ),
  });
  
  const onCreate = (entity: any) => {
    create({
      endpoint: `${RemoteEndpoints.stakeholdersPublicoConfirmar}/${key}`,
      properties: { reducer: reducer },
      data: { password: entity.password },
    });
  };

  const onSubmit = async (data: any) => {
    delete data.lgpd;
    onCreate(data);
  };

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  const openModal = () =>
    modals.open({
      title: (
        <Text fz="xl" fw={500}>
          Como utilizar o QR Code de autenticação
        </Text>
      ),
      size: "xl",
      children: (
        <>
          <iframe
            className={classes.video}
            src="https://www.youtube.com/embed/rR7H3wi-iVg?controls=0"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </>
      ),
    });

  return (
    <Container size={640} my={30}>
      <Title className={classes.title} align="center">
        Confirmação de cadastro
      </Title>
      <Text c="dimmed" fz="sm" mt="md" ta="center">
        Agadecemos por participar do RedD. Precisamos de algumas informações
        para finalizar seu cadastro.
      </Text>
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <LoadingOverlay
          visible={!!reducerPreConsulta.state.status?.isFetching}
        />
        <form onSubmit={form.onSubmit((a) => onSubmit(a), onFormError)}>
          <Stepper active={active} breakpoint="sm">
            <Stepper.Step label="Primeiro passo" description="Criar senha">
              <Text c="dimmed" fz="sm" mt="md" ta="center">
                Crie uma senha para acessar o sistema
              </Text>
              <PasswordInput
                label="Senha"
                placeholder="**********"
                mt="md"
                size="md"
                {...form.getInputProps("password")}
              />
              <PasswordInput
                label="Confirme sua senha"
                placeholder="**********"
                mt="md"
                size="md"
                {...form.getInputProps("password_confirm")}
              />
              <Checkbox
                label="Concordo com os termos iniciais de gestão de dados e LGPD"
                mt="xl"
                size="xs"
                {...form.getInputProps("lgpd")}
              />
            </Stepper.Step>
            <Stepper.Step
              label="Segundo Passo"
              description="Autenticação Multi-fatores"
            >
              <Text c="dimmed" fz="sm" mt="md" ta="center" mb="md">
                Este QR Code de autenticação multifatores dará mais proteção a
                sua conta e empresa. Você deve escaneá-lo com um aplicativo como
                Google Authenticator ou Microsoft Authenticator. Isso será
                necessário apenas uma vez.
              </Text>
              <Group position="center">
                <Button
                  compact
                  variant="white"
                  className={classes.control}
                  onClick={openModal}
                >
                  Precisa de ajuda? Clique aqui e veja um vídeo tutorial rápido
                </Button>
              </Group>
              <Image
                maw={180}
                mx="auto"
                radius="md"
                src={data?.object?.qrcode}
                alt="QR Code de Autenticação multifatores"
              />
              <Text c="dimmed" fz="sm" mt="md" ta="center" mb="md">
                Quando der tudo certo, o aplicativo gerará códigos de tempo em
                tempo, confirme o código gerado pelo aplicativo abaixo.
              </Text>
            </Stepper.Step>
            <Stepper.Completed>
              <Center m="md">
                <IconCircleCheck color="teal" size={200} />
              </Center>
              <Text c="gray" fz="md" mt="md" ta="center">
                Tudo certo! Agora você poderá logar no sistema.
              </Text>
              <Button component="a" fullWidth href="/" mt="md">
                Leve-me para o login
              </Button>
            </Stepper.Completed>
          </Stepper>
          <Group position="apart" mt="lg" className={classes.controls}>
            <Hidden hide={active !== 1}>
              <Button fullWidth className={classes.control} onClick={nextStep}>
                Pronto!
              </Button>
            </Hidden>
            <Hidden hide={active !== 0}>
              <Button
                fullWidth
                type="submit"
                disabled={!form.isValid()}
                className={classes.control}
                loading={reducer.state.status?.isSubmitting}
              >
                Confirmar
              </Button>
            </Hidden>
          </Group>
        </form>
      </Paper>
    </Container>
  );
};
