import {
  Box,
  Button,
  Center,
  Container,
  Group,
  LoadingOverlay,
  Paper,
  Stepper,
  Text,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCircleCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PreCadastroReferenciaComercialForm } from "./PreCadastroReferenciaComercial.form";
import { useFileUploadDocumentoPrecadastro } from "./useFileUploadDocumentoPrecadastro.hook";
import { PreCadastroInformacoesForm } from "./PreCadastroMaisInformacoes.form";
import { useRemoteOperation } from "../../hooks";
import { useRemoteDataReducer } from "../../hooks/remote/useCRUDHooks";
import { UploadDocumentosEmpresa } from "../../components/panel/UploadDocumentosEmpresa.panel";
import { RemoteEndpoints } from "../../constants/RemoteEndpoints.const";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(26),
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));

export const PreCadastroEmpresaDocumentosPage = () => {

  const navigate = useNavigate();
  const key = new URLSearchParams(useLocation().search).get("key");
  const { classes } = useStyles();
  const reducer = useRemoteDataReducer();
  const location = useLocation();
  const fileUpload = useFileUploadDocumentoPrecadastro({ reducer: reducer });
  const { update, fetchSingle, patch } = useRemoteOperation({ reducer: reducer });
  const { status, data } = reducer.state;

  const [current, setCurrent] = useState<string>("");
  const [active, setActive] = useState(0);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const identifyStep = () => {
    if (data?.object?.status === "DOCUMENTADO") {
      setActive(3);
    }
    if (data?.object?.step === "DOCUMENTOS_OK") {
      setActive(1);
    } else if (data?.object?.step === "INDICACOES_OK") {
      setActive(2);
    } else if (data?.object?.step === "MAIS_INFORMACOES_OK") {
      setActive(3);
    } else {
      setActive(0);
    }
  }

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (
      status?.isSuccessful &&
      data?.object &&
      data.object?.status !== "ATENDIDO" &&
      data.object?.status !== "DOCUMENTADO"
    ) {
      navigate("/404");
    }
    if (status?.isSuccessful) {
      identifyStep();
    }
  }, [status]);

  useEffect(() => {
    if (key) {
      reducer.dispatch({ type: "INITIATING" });
      findPreCadastroEmpresa(key);
    } else {
      navigate("/404");
    }
  }, [location]);

  const findPreCadastroEmpresa = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.preCadastoEmpresaPublicoBuscar}/${id}`,
      properties: { reducer: reducer },
    });

  const onSubmit = async (updateData: any) => {
    updateData = { ...updateData, ...data?.object };
    const id = updateData?.id || key;
    delete updateData?.id;
    update({
      endpoint: `${RemoteEndpoints.preCadastoEmpresaPublicoAtualizar}/${id}`,
      properties: { reducer: reducer },
      data: updateData,
    });
  };

  return (
    <Container size={800} my="xl">
      <LoadingOverlay visible={status?.isFetching || false} />
      <Box mt="xl">
        <Box my={30}>
          <Title className={classes.title} align="center">
            Pré-Cadastro (Documentação)
          </Title>
          <Text c="dimmed" fz="sm" mt="md" mb="md" ta="center">
            Agadecemos novamente seu interesse em participar do RedD. Precisamos
            de alguns documentos e contatos para analisar melhor a sua empresa.
          </Text>
        </Box>
        <Stepper active={active} breakpoint="sm" mt="xl">
          <Stepper.Step label="Documentação" description="Envio de Documentos">
            <Box mt="xl">
              <UploadDocumentosEmpresa
                onFileDrop={(tag: any, files: any) => fileUpload.execute(tag, key, files)}
                loading={status?.isUpdating}
                files={data?.object}
                current={current}
              />
            </Box>
          </Stepper.Step>
          <Stepper.Step label="Indicações" description="Referencias comerciais">
            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
              <Text c="dimmed" fz="sm" ta="center">
                Informe duas referências comerciais, de preferencia com os
                contatos da área comercial ou financeira.
              </Text>
              <PreCadastroReferenciaComercialForm reducer={reducer} onSubmit={onSubmit} />
            </Paper>
          </Stepper.Step>
          <Stepper.Step label="Informações" description="Mais informações">
            <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
              <PreCadastroInformacoesForm reducer={reducer} onSubmit={onSubmit} />
            </Paper>
          </Stepper.Step>
          <Stepper.Completed>
            <Paper withBorder mt="xl" p="md">
              <Title className={classes.title} align="center" mt="xl">
                Sucesso!
              </Title>
              <Center m="xl">
                <IconCircleCheck
                  color="teal"
                  size={128}
                  style={{ marginRight: "1rem" }}
                />
              </Center>
              <Center>
                <Text size="md">
                  Analisaremos as informações enviadas e entraremos em contato em
                  breve.
                </Text>
              </Center>
              <Center>
                <Button
                  component="a"
                  href="https://ez-health-project.com"
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#00acee",
                      border: 0,
                      height: rem(42),
                      paddingLeft: rem(20),
                      paddingRight: rem(20),
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: theme.fn.darken("#00acee", 0.05),
                      }),
                    },

                    leftIcon: {
                      marginRight: theme.spacing.md,
                    },
                  })}
                  m="xl"
                >
                  Voltar para a tela inicial
                </Button>
              </Center>
            </Paper>
          </Stepper.Completed>
        </Stepper>
      </Box>
    </Container>
  );

};
