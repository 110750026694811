import { createStyles, rem, Box, Center, Title, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCircleCheck, IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { PreCadastroEmpresaForm } from "../form/PreCadastro.form";
import { useRemoteOperation } from "../../hooks";
import { useRemoteDataReducer } from "../../hooks/remote/useCRUDHooks";
import { notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(26),
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
    controls: {
        [theme.fn.smallerThan("xs")]: {
            flexDirection: "column-reverse",
        },
    },
    control: {
        [theme.fn.smallerThan("xs")]: {
            width: "100%",
            textAlign: "center",
        },
    },
}));

export const PreCadastroModal = ({ onFinished }: any) => {

    const preCadastoEmpresaPublicoCriar: string = "https://redd-test.azurewebsites.net/api/pre-cadastro-empresa-criar";
    const reducer = useRemoteDataReducer();
    const { create } = useRemoteOperation({ reducer: reducer });
    const { data, status } = reducer.state;
    const { classes } = useStyles();

    useEffect(() => {
        if (status?.isFailed) {
            notifications.show({
                message: data?.error?.message,
                color: "red",
                icon: <IconX />,
            });
        } else if (status?.isSuccessful) {
            if (data?.object && data?.object?.status === "CADASTRADO") {
                openModal();
                onFinished();
            }
        }
    }, [status]);

    const onCreate = (entity: any) =>
        create({
            endpoint: `${preCadastoEmpresaPublicoCriar}`,
            properties: { reducer: reducer },
            data: entity,
        });

    const openModal = () =>
        modals.open({
            size: "lg",
            centered: true,
            children: (
                <Box mb={50} mx={20}>
                    <Title className={classes.title} align="center">
                        Sucesso!
                    </Title>
                    <Center m="xl">
                        <IconCircleCheck
                            color="#5C985C"
                            size={128}
                            style={{ marginRight: "1rem" }}
                        />
                    </Center>
                    <Text size="md" align="center">
                        Tudo certo! Analisaremos as informações enviadas e entraremos em
                        contato em breve.
                    </Text>
                </Box>
            ),
        });

    return (
        <>
            <Title className={classes.title} align="center">
                Pré-Cadastro
            </Title>
            <Text c="dimmed" fz="sm" mt="md" ta="center">
                Agadecemos seu interesse em participar do RedD. Precisamos de
                algumas informações para entrar em contato com você, o mais breve
                possível.
            </Text>
            <Box mt="xl">
                <PreCadastroEmpresaForm
                    onSubmit={onCreate}
                    reducer={reducer}
                />
            </Box>
        </>
    );

}
