
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/Home.page";
import { NotFoundPage } from "./pages/NotFoundPage.page";
import { PreCadastroEmpresaDocumentosPage } from "./pages/pre-cadastro-empresa-documentos/PreCadastroEmpresaDocumentos.page";
import { FinalizacaoCadastroStakeholder } from "./pages/finalizacao-cadastro-stakeholder/FinalizacaoCadastroStakeholder.page";
import { IniciarPreCadastroEmpresaPage } from "./pages/iniciar-pre-cadastro/IniciarPreCadastroEmpresa.page";


const RouteListComponent = (): JSX.Element => (
    <BrowserRouter>
        <Routes>
            { /* Rotas fechadas */}
            <Route path="/" element={<HomePage />} />
            <Route
                path="/precadastro/empresa"
                element={
                    <IniciarPreCadastroEmpresaPage />
                }
            />
            <Route
                path="/precadastro/empresa/documentos"
                element={
                    <PreCadastroEmpresaDocumentosPage />
                }
            />
            <Route
                path="/precadastro/stakeholder"
                element={
                    <FinalizacaoCadastroStakeholder />
                }
            />
            { /* Outras rotas */}
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    </BrowserRouter>
);

export default RouteListComponent;