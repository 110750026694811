import { RemoteEndpoints } from "../../constants/RemoteEndpoints.const";
import { RemoteClient } from "../../hooks";
import { BlobStorageService } from "../../shared/providers/infrastructure/azurestorage/BlobStorageService.proto";



export const useFileUploadDocumentoPrecadastro = (properties: any) => {

    const execute = async (
        tag: any, key: any, files: any
    ) => {

      const file = files[0];
      const imageData = {
        bucket: "DOCUMENTO_PRE_CADASTRO_EMPRESA",
        filename: file.name,
        contentType: file.type,
        size: file.size,
      };
  
      properties.reducer.dispatch({ type: "UPDATING", payload: null });
  
      const preUploadResult = await RemoteClient.create(
        `${RemoteEndpoints.preCadastoEmpresaPublicoUpload}/${key}?type=${tag}`,
        imageData,
      );
      if (preUploadResult?.data) {
        try {
          debugger
          var sas = preUploadResult.data.documento.url + '?' + preUploadResult.data.documento.sasToken;
          await BlobStorageService.uploadFiles([file], sas);
          properties.reducer.dispatch({
            type: "UPDATE_SUCCESS",
            payload: preUploadResult.data.preCadastroEmpresa,
          });
          //setCurrent("");
        } catch (e) {
          await RemoteClient.create(
            `${RemoteEndpoints.preCadastoEmpresaPublicoUpload}/${key}?type=${tag}&clear=true`,
            null,
          );
          properties.reducer.dispatch({ type: "ERROR", payload: preUploadResult.error });
        }
      } else {
        properties.reducer.dispatch({ type: "ERROR", payload: preUploadResult.error });
      }

    }

    return { execute: execute };

}