import { Container, Divider } from "@mantine/core"

export const DividerWithContainerComponent = () => {

    return (
        <Container size="lg">
            <Divider my="sm" />
        </Container>
    )

}